import React from "react";
import { Carousel } from '@trendyol-js/react-carousel';
import {
    Link
} from "react-router-dom";
import {
    useRouteMatch
} from "react-router-dom";
import { minHeight } from "@mui/system";

export function CarouselItem(props: any) {
    const doc = props.doc;
    const productCode = props.doc.product_id;
    const imageUrl = "https://newpig.scene7.com/is/image/NewPig/PRD_" + productCode + "_ISET?$PB02$";
    //const imageUrl2 = "https://newpig.scene7.com/is/image/NewPig/PRD_" + productCode + "_ISET?wid=376&hei=499"


    return (
        <div className="single_product">
            <div className="product_name">
                <h4 ><Link to={'/product/' + doc.product_id}>{doc.product_id} </Link></h4>
                <h3>
                    <Link to={'/product/' + doc.product_id}>
                        {doc.title}
                    </Link>
                </h3>
                <p className="manufacture_product"><a href="/">Accessories</a></p>
            </div>
            <div className="product_thumb">
                <Link className="primary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>
                <Link className="secondary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>



            </div>
            <div className="product_content">
                <div className="product_ratings">
                    <ul>
                        <li><Link to={'/product/' + doc.product_id}><i className="ion-star"></i></Link></li>
                        <li><Link to={'/product/' + doc.product_id}><i className="ion-star"></i></Link></li>
                        <li><Link to={'/product/' + doc.product_id}><i className="ion-star"></i></Link></li>
                        <li><Link to={'/product/' + doc.product_id}><i className="ion-star"></i></Link></li>
                        <li><Link to={'/product/' + doc.product_id}><i className="ion-star"></i></Link></li>
                    </ul>
                </div>
                <div className="product_footer d-flex align-items-center">
                    <div className="price_box">
                    {
                            (doc.price_effective)
                            ? 
                            <React.Fragment>
                            <span className="current_price">$ {doc.price_effective.toFixed(2)}</span>
                            <span className="old_price">{doc.price.toFixed(2)}</span>
                            </React.Fragment>
                            : 
                            <span className="current_price">$ {doc.price && doc.price.toFixed(2)}</span>
                        }

                    </div>
                    <div className="add_to_cart">
                        <a href="cart.html" title="add to cart"><span className="lnr lnr-cart"></span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}


export function CarouselWrapper(props: any) {
    const docs = props.docs;
    return (
        <React.Fragment>


            <Carousel key={'carousel-' + props.originalProductId} show={5} slide={2}
                transition={0.5}
                infinite={false}
                className="productCarousel">

                {
                    docs.map((doc: any, index: number) => {
                        return <CarouselItem key={'carousel-item' + doc.id + index} doc={doc} />
                    })
                }
            </Carousel>

        </React.Fragment>
    )
}

export function DocDescription(props: any) {
    const doc = props.doc;
    const description = (doc.extended_description != null) ? doc.extended_description : "";
    return (
        <>
        {(description.length >0 )
        ? <> - {description}</>
        : null
        }
       </>
    )
}
export function ResourcesAndDownloads(props: any) {
    const matchParams: any = useRouteMatch().params;

    const productId = matchParams.id;
    const name = props.zone.zone.name;
    const solrResponse = props.zone.render;



    var docs = [];
    var groupId = "product_id";
    if (solrResponse && solrResponse.grouped && solrResponse.grouped[groupId]) {
        var groups: any = solrResponse.grouped[groupId].groups;
        groups.forEach((group: any) => {
            var representativeRecord = group.doclist.docs[0];
            docs.push(representativeRecord);
        })
    }
    else if (solrResponse && solrResponse.response && solrResponse.response.docs) {
        docs = solrResponse.response.docs;
    }



    return (
        <React.Fragment>
           {(docs.length>0)
           ?<>
           
           <section className="product_area mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>{name}</h3>
                            {                                
                                docs.map((doc: any, index: number) => {
                                    return (                      
                                    <li key={doc.title}><a target="_new" href={doc.url} className="supporting_document_title">{doc.title}</a>
                                        <DocDescription doc={doc}/>
                                    </li>
                                    )          
                                })
                            }
                        </div>
                    </div>
                </div>
            </section> 
           </>
           :<></>
           }
                      
        </React.Fragment>
    )
}
export default ResourcesAndDownloads